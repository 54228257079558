<template>
    <page
        id="settings"
        :title="$root.translate('Settings')"
        :status="page_status"
        :waiting="waiting"
        :is-root="true"
        @statused="statused"
    >
        <tabs
            v-if="!!edited_record"
            :tabs="tabs"
            :current-tab-id="current_tab_id"
            @click="current_tab_id = $event"
        >
            <div v-if="current_tab_id === 'reporting'" class="mt-8">
                <form-editor
                    id="report_notification"
                    :data-version="edited_record.id"
                    name="report_notification"
                    v-model="edited_record.report_notification"
                    :headers="0"
                    :styles="false"
                    :links="false"
                    :lists="false"
                    :quotes="false"
                    :tokens="accelo_notification_tokens"
                    size="lg"
                    :required="false"
                    class="mb-4"
                    @input="save"
                >
                    <template v-slot:label>{{ $root.translate("Monthly Email Notification") }}</template>
                </form-editor>
                <div class="columns">
                    <div class="column col-6 col-sm-12 mb-4">
                        <report-notes
                            class="mb-8"
                        />

                        <report-sections
                            :record="edited_record"
                        />
                    </div>
                    <div class="column col-6 col-sm-12 mb-4">
                        <div v-if="!!edited_record" class="card">
                            <div class="card-header">
                                <div class="card-title h6">{{ $root.translate("Report Content") }}</div>
                            </div>
                            <div class="card-body">
                                <form-input
                                    id="report_title"
                                    name="report_title"
                                    v-model="edited_record.report_title"
                                    :required="false"
                                    @input="save"
                                >
                                    <template v-slot:label>{{ $root.translate("Title") }}</template>
                                </form-input>

                                <label class="form-label mb-2">{{ $root.translate("Header Logo") }}</label>
                                <div
                                    class="nibnut-aspect-ratio-box nibnut-ratio-logo mb-4"
                                >
                                    <div>
                                        <image-upload-input
                                            v-if="!!edited_record"
                                            name="report_logo_url"
                                            :url="edited_record.report_logo_url || ''"
                                            :alt="$root.translate('Report Header Logo')"
                                            :accepts="upload_accepts('report_logo_url')"
                                            :uploading="!!uploading.report_logo_url"
                                            :uploaded="uploaded.report_logo_url||0"
                                            :error="has_error('report_logo_url')"
                                            class="logo"
                                            @upload="upload_file_list('report_logo_url', $event)"
                                            @clear="confirm_clear_file('report_logo_url')"
                                        >
                                            <template v-slot:empty-title>
                                                {{ $root.translate("Header Logo") }}
                                            </template>
                                        </image-upload-input>
                                    </div>
                                </div>
                                <form-editor
                                    id="report_computer_count_text"
                                    :data-version="edited_record.id"
                                    name="report_computer_count_text"
                                    v-model="edited_record.report_computer_count_text"
                                    :required="false"
                                    @input="save"
                                >
                                    <template v-slot:label>{{ $root.translate("Computer Count Note") }}</template>
                                </form-editor>
                                <form-editor
                                    id="report_footer"
                                    :data-version="edited_record.id"
                                    name="report_footer"
                                    v-model="edited_record.report_footer"
                                    :required="false"
                                    @input="save"
                                >
                                    <template v-slot:label>{{ $root.translate("Footer") }}</template>
                                </form-editor>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <sro-settings
                v-else-if="current_tab_id === 'sro'"
                :record="edited_record"
                class="mt-8"
            />

            <div v-else-if="current_tab_id === 'ext-tools'" class="columns mt-8">
                <div class="column col-3 col-md-4 col-sm-12">
                    <accelo-connector
                        :record="edited_record"
                    />
                </div>
                <div class="column col-9 col-md-8 col-sm-12">
                    <services />
                </div>
            </div>

            <div v-else-if="current_tab_id === 'settings'" class="columns mt-8">
                <div class="column col-6 col-md-12 col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <span class="h4">{{ $root.translate("Company Branding") }}</span>
                        </div>
                        <div class="card-body">
                            <div
                                class="nibnut-aspect-ratio-box nibnut-ratio-logo"
                            >
                                <div>
                                    <image-upload-input
                                        v-if="!!edited_record"
                                        name="app_logo_url"
                                        :url="edited_record.app_logo_url || ''"
                                        :alt="$root.translate('Support My Mac Logo')"
                                        :accepts="upload_accepts('app_logo_url')"
                                        :uploading="!!uploading.app_logo_url"
                                        :uploaded="uploaded.app_logo_url||0"
                                        :error="has_error('app_logo_url')"
                                        class="logo"
                                        @upload="upload_file_list('app_logo_url', $event)"
                                        @clear="confirm_clear_file('app_logo_url')"
                                    >
                                        <template v-slot:empty-title>
                                            {{ $root.translate("Logo") }}
                                        </template>
                                    </image-upload-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column col-6 col-md-12 col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <span class="h4">{{ $root.translate("User Management") }}</span>
                        </div>
                        <div class="card-body">
                            <invitation-expiries
                                id="default_invitation_delay"
                                name="default_invitation_delay"
                                v-model="edited_record.default_invitation_delay"
                                :required="true"
                                :saving="saving('default_invitation_delay')"
                                :error="has_error('default_invitation_delay')"
                                @input="save"
                            />
                        </div>
                    </div>
                </div>
                <div v-if="!!edited_record && is_developer" class="column col-12 mt-8">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-title h5">{{ $root.translate("Developer Zone") }}</div>
                        </div>
                        <div class="card-body">
                            <bitbucket-connector
                                :record="edited_record"
                            />
                            <beta-tests
                                class="mt-8"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </tabs>

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </page>
</template>

<script type="text/javascript">
import { is_record_editor_page, handles_uploads } from "@/nibnut/mixins"
import { addl_misc_utilities } from "@/custom/mixins"

import { Tabs, InvitationExpiries, FormInput, FormEditor, ImageUploadInput } from "@/nibnut/components"
import BitbucketConnector from "./BitbucketConnector"
import AcceloConnector from "./AcceloConnector"
import Services from "./Services"
import ReportNotes from "./ReportNotes"
import ReportSections from "./ReportSections"
import BetaTests from "./BetaTests"
import SroSettings from "./SroSettings"

export default {
    mixins: [is_record_editor_page, addl_misc_utilities, handles_uploads],
    components: {
        Tabs,
        InvitationExpiries,
        FormInput,
        FormEditor,
        ImageUploadInput,
        BitbucketConnector,
        AcceloConnector,
        Services,
        ReportNotes,
        ReportSections,
        BetaTests,
        SroSettings
    },
    methods: {
        grab_record_id () {
            this.load_vocabularies([
                this.constants("vocabularies", "VOCABULARY_SRO_STATUS").id
            ])
            this.record_id = 1
        },
        upload_accepts (name, filename = null) {
            if(filename) return !!filename.match(/\.(png|jpe?g|svg)$/i)
            return "image/jpeg,image/png,image/svg,.jpg,.jpeg,.png,.svg"
        },
        upload_data (mode) {
            return {
                entity: this.entity,
                id: this.record_id
            }
        },
        upload_attach_error (name, error) {
            this.add_error(name, error.message)
        },
        upload_detach_error (name, error) {
            this.add_error(name, error.message)
        },
        upload_detached (name, response) {
            this.done_confirming()
        },
        confirmed () {
            if(this.confirming === "clear-file") this.clear_file()
            else this.done_confirming()
        }
    },
    computed: {
        tabs () {
            return [
                { id: "reporting", title: this.translate("Reporting") },
                { id: "sro", title: this.translate("SRO") },
                { id: "ext-tools", title: this.translate("External Tools") },
                { id: "settings", title: this.translate("Settings") }
            ]
        },
        accelo_notification_tokens () {
            return [
                { id: "name", label: "Recipient Name" },
                { id: "range", label: "Report Range" },
                { id: "button", label: "Report URL" }
            ]
        }
    },
    data () {
        return {
            entity: "setting",
            entityName: "Setting:::Settings",
            current_tab_id: "reporting"
        }
    }
}
</script>

<style lang="scss">
#settings {
    .card.full-height {
        height: 100%;
    }
    .logo {
        & > .empty {
            padding: 0.2rem;
        }
    }
}
</style>
