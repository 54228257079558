<template>
    <div v-if="!!app_context" class="card accelo">
        <div class="card-body text-center">
            <img
                src="@/assets/img/accelo_logo.svg"
                :style="{ 'vertical-align': 'bottom' }"
            />
            <form-dropdown
                id="accelo_abbrev_field"
                name="accelo_abbrev_field"
                :value="record.accelo_abbrev_field"
                :options="record.accelo_profiles"
                id-field="field_name"
                label-field="field_name"
                :empty-label="$root.translate('None')"
                :required="false"
                :saving="saving('accelo_abbrev_field')"
                :error="has_error('accelo_abbrev_field')"
                class="mt-4"
                @input="save_accelo_abbrev"
            />
        </div>
    </div>
</template>

<script type="text/javascript">
import { misc_utilities, handles_saving } from "@/nibnut/mixins"

import { FormDropdown } from "@/nibnut/components"

export default {
    name: "AcceloConnector",
    mixins: [misc_utilities, handles_saving],
    components: {
        FormDropdown
    },
    methods: {
        revoke () {
            this.save_field("accelo", "revoke")
        },
        save_accelo_abbrev (value, field, option) {
            this.save_data_for_record_id(this.entity, this.record_id, { accelo_abbrev_field: option.field_name, accelo_abbrev_field_id: parseInt(option.id) || 0 })
        }
    },
    props: {
        record: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            record_id: 1,
            entity: "setting"
        }
    }
}
</script>

<style lang="scss">
.card.accelo {
    img {
        height: 1rem;
    }
}
</style>
