<template>
    <div>
        <div class="columns">
            <div class="column flex-static">
                <label class="form-label">{{ translate("Custom Fields for") }}:</label>
            </div>
            <div class="column flex-static">
                <form-dropdown
                    id="type"
                    name="type"
                    :value="current_type"
                    :options="filtered_rows"
                    :required="true"
                    @input="set_current_type"
                />
            </div>
        </div>
        <div class="text-right my-2">
            <default-button
                v-if="!reordering"
                flavor="link"
                size="sm"
                @click.prevent="start_reordering"
            >
                <span>{{ translate("Re-order") }}</span>
            </default-button>
            <default-button
                v-else
                flavor="link"
                color="error"
                size="sm"
                @click.prevent="stop_reordering(false)"
            >
                <span>{{ translate("Cancel") }}</span>
            </default-button>
            <default-button
                v-if="reordering"
                flavor="link"
                color="success"
                size="sm"
                @click.prevent="stop_reordering(true)"
            >
                <span>{{ translate("Save") }}</span>
            </default-button>
        </div>
        <table v-if="!!current_type.settings" class="table">
            <thead>
                <tr>
                    <th>{{ translate("Field") }}</th>
                    <th class="col-buttons">{{ translate("Checkin") }}</th>
                    <th class="col-buttons">&nbsp;</th>
                </tr>
            </thead>
            <draggable
                :list="current_type.settings.fields"
                tag="tbody"
                :disabled="!reordering"
            >
                <tr
                    v-for="(identifier, index) in current_type.settings.fields"
                    :key="index"
                >
                    <td>
                        {{ custom_fields[identifier].name }}
                    </td>
                    <td class="col-buttons text-center">
                        <form-toggle-input
                            :id="`checkin-${index}`"
                            name="checkin"
                            type="checkbox"
                            :title="translate('Show On Checkin')"
                            :value="!!custom_fields[identifier].checkin"
                            :required="false"
                            @input="toggle_checkin(identifier)"
                        />
                    </td>
                    <td class="col-buttons">
                        <open-icon
                            v-if="reordering"
                            glyph="grip-lines"
                            class="text-gray"
                        />
                        <default-button
                            v-else
                            flavor="link"
                            color="error"
                            size="sm"
                            @click.prevent="confirm_record_delete(index, custom_fields[identifier])"
                        >
                            <open-icon glyph="trash" />
                        </default-button>
                    </td>
                </tr>
            </draggable>
        </table>
        <form-dropdown
            v-if="!reordering"
            id="add"
            name="add"
            :value="0"
            :options="available_fields"
            id-field="identifier"
            :empty-label="translate('Add a field...')"
            :required="false"
            class="mt-4"
            @input="add_field"
        />
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script>
import orderBy from "lodash/orderBy"

import { is_remote_data_table_source, handles_saving, confirms } from "@/nibnut/mixins"

import {
    FormDropdown,
    FormToggleInput,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"
import draggable from "vuedraggable"

export default {
    name: "SroFieldMappings",
    mixins: [is_remote_data_table_source, handles_saving, confirms],
    components: {
        FormDropdown,
        FormToggleInput,
        DefaultButton,
        OpenIcon,
        draggable
    },
    methods: {
        pre_load () {
            if(!this.state.filter) this.set_state_value("filter", this.constants("vocabularies", "VOCABULARY_SRO_TYPE").id)
        },
        post_load () {
            if(this.page_autoscroll) this.page_autoscroll()
            const types = this.filtered_rows
            if(types.length) this.set_current_type(0, "", types[0])
        },
        set_current_type (value, field, option) {
            this.current_type = option
            this.record_id = this.current_type.id
            this.reordering = false
        },
        start_reordering () {
            this.initial_sort_order = [...this.current_type.settings.fields]
            this.reordering = true
        },
        stop_reordering (save) {
            if(save) this.save(this.current_type.settings, "settings")
            else this.current_type.settings.fields = this.initial_sort_order
            this.initial_sort_order = []
            this.reordering = false
        },
        save (value, field) {
            if(this.current_type) {
                if(this.current_type[field] !== value) this.current_type[field] = value
                if(this.current_type.id) this.save_field(this.current_type[field], field)
            }
        },
        toggle_checkin (identifier) {
            const services = this.setting("services")
            if(services && services.accelo && services.accelo.fields && services.accelo.fields[identifier]) {
                const fields = { ...services.accelo.fields }
                fields[identifier].checkin = !fields[identifier].checkin
                this.$emit("input", fields, "accelo_sro_fields")
            }
        },
        add_field (value) {
            if(!this.current_type) return
            const settings = { ...this.current_type.settings }
            settings.fields.push(value)
            this.save(settings, "settings")
        },
        confirm_record_delete (index, field) {
            if(!this.current_type) return
            this.deleting = index
            this.confirm(
                {
                    type: "error",
                    title: this.translate("Delete {name}", { name: field.name }),
                    message: this.translate("Do you really want to delete this field? IT will no longer show up in the SRO UI. There is no undo."),
                    cancel: this.translate("Keep"),
                    ok: this.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "delete-record") {
                if(this.deleting >= 0) {
                    const settings = { ...this.current_type.settings }
                    settings.fields.splice(this.deleting, 1)
                    this.save(settings, "settings")
                    this.deleting = -1
                    this.done_confirming()
                }
            } else this.done_confirming()
        }
    },
    computed: {
        fields () {
            return ["fieldset::editor"]
        },
        custom_fields () {
            const custom_fields = {}
            const services = this.setting("services")
            if(services && services.accelo && services.accelo.fields && this.current_type) {
                Object.keys(services.accelo.fields).forEach(identifier => {
                    const field = services.accelo.fields[identifier]
                    if((field.source === "profiles") || (parseInt(field.link_type_id) === this.current_type.remote_id) || (this.current_type.settings.fields.indexOf(identifier) >= 0)) custom_fields[identifier] = field
                })
            }
            return custom_fields
        },
        available_fields () {
            if(this.current_type && this.current_type.settings) {
                const custom_fields = this.custom_fields
                const fields = []
                Object.values(custom_fields).forEach(field => {
                    if(!field.archived && (this.current_type.settings.fields.indexOf(field.identifier) < 0)) fields.push(field)
                })
                return orderBy(fields, "name", "asc")
            }
            return []
        }
    },
    data () {
        return {
            entity: "term",
            entityName: "SRO type:::SRO types",
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "sort_order",
                sort_dir: "asc",
                filter_on: "vocabulary",
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },

            current_type: {},
            deleting: -1,
            initial_sort_order: [],
            reordering: false
        }
    }
}
</script>
