<template>
    <div id="services" class="card">
        <div class="card-body">
            <data-table
                id="services-list"
                :columns="visible_columns"
                :rows="filtered_rows"
                :search="state.search"
                :total="state.total"
                :found="state.found"
                :current-page="state.page"
                :total-pages="nb_pages"
                :can-add="false"
                :clickable="true"
                :searchable="false"
                :show-head="false"
                :show-totals="false"
                @sort="sort_by"
                @page="goto_page"
            >
                <template
                    v-slot:summary="{ row }"
                >
                    <h6>{{ row }}</h6>
                </template>
                <template
                    v-slot:tbody="{ row, field }"
                >
                    <div v-if="field === 'name'">
                        <form-input
                            :id="`${row.slug}-name`"
                            name="name"
                            v-model="row.name"
                            :required="true"
                            @input="save_field_for_record_id('service', row.id, $event, 'name')"
                        />
                        <div class="columns">
                            <div class="column text-gray">
                                <label class="form-label label-sm">
                                    {{ row.slug }}
                                </label>
                            </div>
                            <form-toggle-input
                                id="billable"
                                name="billable"
                                type="checkbox"
                                size="sm"
                                :value="row.billable"
                                :required="false"
                                class="column col-auto"
                                @input="save_field_for_record_id('service', row.id, !row.billable, 'billable')"
                            >
                                {{ $root.translate('Billable') }}
                            </form-toggle-input>
                        </div>
                    </div>
                    <div v-else-if="field === 'settings'">
                        <default-button
                            v-if="!!row.settings"
                            @click="edit_service_settings(row)"
                        >
                            <open-icon
                                glyph="cog"
                            />
                        </default-button>
                    </div>
                    <div v-else-if="field === 'upsell_message'">
                        <default-button
                            :color="!!row.upsell_message ? 'primary' : 'regular'"
                            @click="edit_upsell_message_for_service(row)"
                        >
                            <open-icon :glyph="!!row.upsell_message ? 'comment-dots' : 'comment-alt'" />
                        </default-button>
                    </div>
                    <div v-else-if="field === 'active'">
                        <default-button
                            :color="row.active ? 'success' : 'error'"
                            @click="save_field_for_record_id('service', row.id, !row.active, 'active')"
                        >
                            <open-icon
                                :glyph="row.active ? 'check' : 'ban'"
                            />
                        </default-button>
                    </div>
                    <span v-else>{{ row[field] }}</span>
                </template>
            </data-table>

            <upsell-editor-dialog
                :service="upsell_record"
                :data-version="upsell_record.id || 0"
                :value="upsell_record.upsell_message"
                :show.sync="upsell_editing"
                @input="save_field_for_record_id('service', upsell_record.id, $event, 'upsell_message')"
            />

            <modal-dialog
                id="service-settings-editor"
                :show.sync="editing"
                class="modal-lg"
            >
                <template v-slot:title>
                    <span class="h5">{{ translate("{name} Settings", { name: edited_record.name }) }}</span>
                </template>
                <div v-if="edited_record.slug === constants('services', 'CRASHPLAN').id">
                    <h5>{{ translate("Pricing Buckets") }}</h5>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>{{ translate("Bucket") }}</th>
                                <th class="col-max">{{ translate("Up to") }}&nbsp;(&lt;=)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="bucket in buckets"
                                :key="bucket.slug"
                            >
                                <td v-if="bucket.slug !== constants('Service::BUCKET_DEFAULT')">
                                    <form-input
                                        :id="`bucket-name-${bucket.slug}`"
                                        name="name"
                                        v-model="bucket.name"
                                        :required="true"
                                        @input="save_bucket_field"
                                    />
                                </td>
                                <td v-else>
                                    <label class="form-label">
                                        {{ default_bucket_name }}
                                    </label>
                                </td>
                                <td v-if="bucket.slug !== constants('Service::BUCKET_DEFAULT')" class="col-max">
                                    <form-input
                                        :id="`bucket-max-${bucket.slug}`"
                                        name="max"
                                        type="stripe"
                                        v-model="bucket.max"
                                        :required="true"
                                        @input="save_bucket_field"
                                    >
                                        <template v-slot:right_addon>
                                            <span class="input-group-addon">
                                                {{ translate("GB") }}
                                            </span>
                                            <default-button
                                                flavor="link"
                                                color="error"
                                                class="input-group-btn"
                                                @click.prevent.stop="remove_bucket(bucket)"
                                            >
                                                <open-icon glyph="trash" />
                                            </default-button>
                                        </template>
                                    </form-input>
                                </td>
                                <td v-else class="col-max">
                                    <label class="form-label">
                                        {{ translate("Infinity") }}
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td v-if="!adding" colspan="2">
                                    <default-button
                                        :block="true"
                                        color="primary"
                                        @click.prevent.stop="add_bucket"
                                    >
                                        {{ translate("Add a new pricing bucket...") }}
                                    </default-button>
                                </td>
                                <td v-else-if="!!added_record">
                                    <form-input
                                        id="bucket-name"
                                        name="name"
                                        v-model="added_record.name"
                                        :required="true"
                                    />
                                </td>
                                <td v-if="!!adding && !!added_record" class="col-max">
                                    <form-input
                                        id="bucket-max"
                                        name="max"
                                        type="stripe"
                                        v-model="added_record.max"
                                        :required="true"
                                    >
                                        <template v-slot:right_addon>
                                            <span class="input-group-addon">
                                                {{ translate("GB") }}
                                            </span>
                                            <default-button
                                                flavor="link"
                                                color="error"
                                                class="input-group-btn"
                                                @click.prevent.stop="stop_add_bucket(false)"
                                            >
                                                <open-icon glyph="times" />
                                            </default-button>
                                            <default-button
                                                flavor="link"
                                                color="success"
                                                class="input-group-btn"
                                                @click.prevent.stop="stop_add_bucket(true)"
                                            >
                                                <open-icon glyph="check" />
                                            </default-button>
                                        </template>
                                    </form-input>
                                </td>
                            </tr>
                        </tfoot>
                    </table>

                    <crashplan-hosts
                        class="mt-8"
                    />
                </div>
            </modal-dialog>
        </div>
    </div>
</template>

<script>
import orderBy from "lodash/orderBy"

import { is_remote_data_table_source, handles_saving } from "@/nibnut/mixins"
import { upsells_services } from "@/custom/mixins"

import { ModalDialog, FormInput, FormToggleInput, DefaultButton, OpenIcon } from "@/nibnut/components"
import CrashplanHosts from "./CrashplanHosts"

export default {
    name: "Services",
    mixins: [is_remote_data_table_source, handles_saving, upsells_services],
    components: {
        ModalDialog,
        FormInput,
        FormToggleInput,
        DefaultButton,
        OpenIcon,
        CrashplanHosts
    },
    watch: {
        editing: "reset_ui"
    },
    methods: {
        reset_ui () {
            this.stop_add_bucket()
        },
        edit_service_settings (service) {
            if(!service.settings) return
            this.edited_record = service
            this.editing = true
        },
        add_bucket () {
            const biggest_bucket = this.biggest_bucket
            this.added_record = {
                name: this.translate("New max Bucket"),
                max: biggest_bucket ? biggest_bucket.max + 100 : 100
            }
            this.adding = true
            setTimeout(() => {
                const node = document.getElementById("bucket-name")
                if(node) {
                    node.focus()
                    node.select()
                }
            }, 250)
        },
        stop_add_bucket (save = false) {
            if(this.added_record) {
                if(save && !!this.added_record.name && !!this.added_record.max) {
                    const settings = { ...this.edited_record.settings }
                    const slug = this.added_record.name.toLowerCase().replace(/[^a-zA-Z0-9]/g, "-")
                    if(settings.pricing_buckets[slug]) this.$error(this.translate("This bucket name already exists. Please use a different one."))
                    else {
                        settings.pricing_buckets[slug] = { slug, name: this.added_record.name, max: this.added_record.max }
                        const ordered_buckets = orderBy(Object.values(settings.pricing_buckets), "max", "asc")
                        settings.pricing_buckets = {}
                        ordered_buckets.forEach(bucket => {
                            settings.pricing_buckets[bucket.slug] = bucket
                        })
                        this.edited_record.settings = settings
                        this.save_field_for_record_id("service", this.edited_record.id, settings, "settings")
                        this.stop_add_bucket()
                    }
                } else {
                    this.adding = false
                    this.added_record = null
                }
            }
        },
        save_bucket_field () {
            this.save_field_for_record_id("service", this.edited_record.id, this.edited_record.settings, "settings")
        },
        remove_bucket (bucket) {
            const settings = { ...this.edited_record.settings }
            if(settings.pricing_buckets[bucket.slug]) {
                delete settings.pricing_buckets[bucket.slug]
                this.edited_record.settings = settings
                this.save_field_for_record_id("service", this.edited_record.id, settings, "settings")
            }
        }
    },
    computed: {
        can_reload () {
            return this.profile_id
        },
        state_identifier () {
            return "services"
        },
        fields () {
            return ["fieldset::list"]
        },
        buckets () {
            if(this.edited_record && this.edited_record.settings && this.edited_record.settings.pricing_buckets) {
                return orderBy(Object.values(this.edited_record.settings.pricing_buckets), "max", "asc")
            }
            return []
        },
        biggest_bucket () {
            const buckets = this.buckets
            if(buckets.length > 1) return buckets[buckets.length - 2]
            return null
        },
        default_bucket_name () {
            if(this.edited_record && this.edited_record.settings && this.edited_record.settings.pricing_buckets) {
                const biggest_bucket = this.biggest_bucket
                if(biggest_bucket) {
                    return this.translate("Anything above {max}GB", { max: this.nibnut_filter("nibnut.number", [biggest_bucket.max / 100, "0,0.00"]) })
                }
            }
            return this.translate("Default Bucket")
        }
    },
    data () {
        return {
            entity: "service",
            entityName: "service:::services",
            columns: {
                name: { label: "Name", sort: "asc", type: "alpha" },
                settings: { label: "Settings", sort: false },
                upsell_message: { label: "Upsell", sort: false },
                active: { label: "Active", sort: false }
            },
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },
            edited_record: {},
            editing: false,
            upsell_message: false,
            added_record: null,
            adding: false
        }
    }
}
</script>

<style lang="scss">
#services {
    table.table {
        tr {
            & > th, & > td {
                white-space: nowrap;

                &.col-max {
                    min-width: 200px;
                }
            }
            & > td {
                vertical-align: top;
            }
            & > td:first-child {
                width: 100%;
            }
        }
    }
}
</style>
