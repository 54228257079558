<template>
    <div>
        <h5>{{ translate("Hosts") }}</h5>
        <data-table
            id="crashplan-hosts-list"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="true"
            :searchable="false"
            class="non-sticky"
            @sort="sort_by"
            @search="search"
            @page="goto_page"
            @add="add_row"
            @click="edit_row"
        >
            <template
                v-slot:summary="{ row }"
            >
                <h6>
                    <a :href="row.url" target="_blank" @click.stop>{{ row.url }}</a>
                </h6>
                <div class="text-small text-gray">
                    <strong>{{ $root.translate("Username") }}</strong>: {{ row.username }}
                </div>
                <default-button
                    color="error"
                    @click.prevent.stop="confirm_record_delete(row)"
                >
                    <open-icon glyph="times-circle" />
                </default-button>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <a
                    v-if="field === 'url'"
                    :href="row.url"
                    target="_blank"
                    @click.stop
                >
                    {{ row.url }}
                </a>
                <div v-else-if="field==='_buttons'" class="text-right">
                    <default-button
                        color="error"
                        size="sm"
                        @click.prevent.stop="confirm_record_delete(row)"
                    >
                        <open-icon glyph="times-circle" />
                    </default-button>
                </div>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>

        <modal-dialog
            id="crashplan-host-editor"
            :show.sync="editing"
        >
            <template v-slot:title><span class="h5">{{ $root.translate("Crashplan Host") }}</span></template>
            <base-form
                v-if="!!editor_record"
                :has-required-fields="true"
                @submit.prevent="maybe_save_record"
            >
                <form-input
                    id="url"
                    name="url"
                    v-model="editor_record.url"
                    :required="true"
                    :saving="saving('url')"
                    :error="has_error('url')"
                    @input="save_record_field"
                >
                    <template v-slot:label>{{ $root.translate("URL") }}</template>
                </form-input>

                <div class="columns">
                    <div class="column col-6 col-sm-12">
                        <form-input
                            id="username"
                            name="username"
                            v-model="editor_record.username"
                            :required="true"
                            :editable="!editor_record.token"
                            :saving="saving('username')"
                            :error="has_error('username')"
                            @input="save_record_field"
                        >
                            <template v-slot:label>{{ $root.translate("Username") }}</template>
                        </form-input>
                    </div>
                    <div class="column col-6 col-sm-12">
                        <password-input
                            id="password"
                            name="password"
                            v-model="editor_record.password"
                            :required="true"
                            :disabled="!!editor_record.token"
                            :saving="saving('password')"
                            :error="has_error('password')"
                            @input="save_record_field"
                        >
                            <template v-slot:label>{{ $root.translate("Password") }}</template>
                        </password-input>
                    </div>
                </div>
            </base-form>

            <template v-slot:footer>
                <div v-if="!!editor_record && !editor_record.id" class="text-center">
                    <default-button
                        :disabled="saving()"
                        class="mr-2"
                        @click.prevent="close"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        color="primary"
                        :waiting="saving()"
                        class="ml-2"
                        @click.prevent="save_record"
                    >
                        {{ $root.translate("Create") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script>
import { is_remote_data_table_source, handles_saving, confirms } from "@/nibnut/mixins"

import {
    DefaultButton,
    OpenIcon,
    ModalDialog,
    BaseForm,
    FormInput,
    PasswordInput
} from "@/nibnut/components"

export default {
    name: "CrashplanHosts",
    mixins: [is_remote_data_table_source, handles_saving, confirms],
    components: {
        DefaultButton,
        OpenIcon,
        ModalDialog,
        BaseForm,
        FormInput,
        PasswordInput
    },
    methods: {
        edit_row (row) {
            this.editor_record = row
            this.editing = true
        },
        add_row () {
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: this.entity }
            ).then(record => {
                this.edit_row(record)
            }).catch(error => {
                this.$error(error.message)
            })
        },
        confirm_record_delete (row) {
            this.editor_record = row
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete {name}", { name: row.url }),
                    message: this.$root.translate("Do you really want to delete this host? All report data from that host will be deleted. There is no undo!"),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "delete-record") {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: this.entity,
                        id: this.editor_record.id
                    }
                ).then(() => {
                    this.done_confirming()
                    this.reset()
                }).catch(error => {
                    this.$error(error.message)
                })
            } else this.done_confirming()
        },
        maybe_save_record () {
            if(!this.editor_record || !!this.editor_record.id) this.close()
            else this.save_record()
        },
        save_record_field (value, field) {
            if(this.editor_record) {
                if(this.editor_record[field] !== value) this.editor_record[field] = value
                if(this.editor_record.id) return this.save_data_for_record_id(this.entity, this.editor_record.id, { [field]: this.editor_record[field], fields: ["fieldset::editor"] })
            }
            return Promise.resolve()
        },
        save_record () {
            this.save_data_for_record_id(this.entity, this.editor_record.id, this.editor_record).then(() => {
                this.refresh()
                this.close()
            })
        },
        saved_data (record, is_new) {
            if(is_new) this.current_records_ids.push(record.id)
        },
        close () {
            this.creating_record = false
            this.editing = false
        }
    },
    computed: {
        fields () {
            return ["fieldset::editor"]
        }
    },
    data () {
        return {
            entity: "crashplan_host",
            entityName: "host:::hosts",
            columns: {
                url: { label: "URL", sort: "asc", type: "alpha" },
                username: { label: "Username", sort: null, type: "alpha" },
                _buttons: { label: " ", sort: false }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "url",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },

            editing: false,
            editor_record: {}
        }
    }
}
</script>
