<template>
    <div class="columns">
        <div class="column col-6 col-sm-12">
            <form-editor
                id="sro_terms_of_service"
                :data-version="record.id"
                name="sro_terms_of_service"
                v-model="record.sro_terms_of_service"
                :lists="false"
                :quotes="false"
                size="lg"
                :full-height="false"
                :required="false"
                @input="save"
            >
                <template v-slot:label>{{ translate("Terms and Conditions") }}</template>
            </form-editor>
            <form-editor
                id="sro_data_terms_of_service"
                :data-version="record.id"
                name="sro_data_terms_of_service"
                v-model="record.sro_data_terms_of_service"
                :lists="false"
                :quotes="false"
                size="lg"
                :full-height="false"
                :required="false"
                class="mt-8"
                @input="save"
            >
                <template v-slot:label>{{ translate("Data Terms and Conditions") }}</template>
            </form-editor>
        </div>
        <div class="column col-6 col-sm-12">
            <sro-field-mappings
                @input="save"
            />
        </div>
        <div v-if="is_developer" class="column col-12 mt-8">
            <div class="card">
                <div class="card-header">
                    <div class="card-title h5">{{ translate("Developer Zone") }}</div>
                </div>
                <div class="card-body">
                    <h6>{{ translate("Field Mappings") }}</h6>
                    <div class="columns">
                        <div
                            v-for="field in fields"
                            :key="field"
                            class="column d-flex"
                        >
                            <label class="form-label flex-static mr-2">{{ field }}</label>
                            <form-dropdown
                                :id="`${field}-map`"
                                :name="field"
                                :value="record.accelo_sro_field_mappings[field]"
                                :options="custom_fields"
                                id-field="identifier"
                                :required="true"
                                class="flex-static"
                                @input="map_field"
                            />
                        </div>
                    </div>
                    <form-dropdown
                        id="sro_final_status_id"
                        name="sro_final_status_id"
                        :value="record.sro_final_status_id"
                        :options="closed_statuses"
                        :required="false"
                        @input="save"
                    >
                        <template v-slot:label>{{ translate("Final Status") }}</template>
                    </form-dropdown>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { profile_utilities, handles_saving } from "@/nibnut/mixins"
import { addl_misc_utilities } from "@/custom/mixins"

import {
    FormEditor,
    FormDropdown
} from "@/nibnut/components"
import SroFieldMappings from "./SroFieldMappings"

export default {
    name: "SroSettings",
    mixins: [profile_utilities, addl_misc_utilities, handles_saving],
    components: {
        FormEditor,
        FormDropdown,
        SroFieldMappings
    },
    methods: {
        save (value, field) {
            if(this.record) {
                if(this.record[field] !== value) this.record[field] = value
                if(this.record.id) this.save_field(this.record[field], field)
            }
        },
        map_field (value, field) {
            if(this.record) {
                const accelo_sro_field_mappings = { ...this.record.accelo_sro_field_mappings }
                accelo_sro_field_mappings[field] = value
                this.save(accelo_sro_field_mappings, "accelo_sro_field_mappings")
            }
        }
    },
    computed: {
        fields () {
            if(!this.record || !this.record.accelo_sro_field_mappings) return []
            return Object.keys(this.record.accelo_sro_field_mappings)
        },
        custom_fields () {
            const custom_fields = []
            const services = this.setting("services")
            if(services && services.accelo && services.accelo.fields) return Object.values(services.accelo.fields)
            return custom_fields
        },
        closed_statuses () {
            return this.vocabulary("VOCABULARY_SRO_STATUS").filter(status => status.settings.standing === "closed")
        }
    },
    props: {
        record: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            record_id: 1,
            entity: "setting"
        }
    }
}
</script>
