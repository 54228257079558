<template>
    <div id="report-sections" class="card">
        <div class="card-header">
            <div class="card-title h6">{{ translate("Report Sections") }}</div>
        </div>
        <div class="card-body">
            <details
                v-for="section in record.report_sections"
                :key="section.id"
                class="accordion my-2"
            >
                <summary class="accordion-header">
                    <i class="icon las la-arrow-right mr-1"></i>
                    {{ section.title }}<span v-if="section.title !== section.original_title" class="text-gray"> ({{ section.original_title }})</span>
                </summary>
                <div class="accordion-body">
                    <form-input
                        id="title"
                        name="title"
                        v-model="section.title"
                        :required="true"
                        @input="save"
                    >
                        <template v-slot:label>{{ translate("Section Title") }}</template>
                    </form-input>
                    <form-editor
                        id="description"
                        :data-version="record.id"
                        name="description"
                        v-model="section.description"
                        :required="false"
                        @input="save"
                    >
                        <template v-slot:label>{{ translate("Tooltip") }}</template>
                        <template v-slot:hint>{{ translate("Leave empty to hide the tooltip bubble for this section.") }}</template>
                    </form-editor>
                    <form-editor
                        id="placeholder"
                        :data-version="record.id"
                        name="placeholder"
                        v-model="section.placeholder"
                        :tokens="section_tokens(section.id)"
                        :required="false"
                        @input="save"
                    >
                        <template v-slot:label>{{ translate("Placeholder Text") }}</template>
                        <template v-slot:hint>
                            {{ translate("When a section has no data to report, (but is till active for the client) it is not shown by default. If you would like some generic text to be shown instead, you can set it here.") }}
                            <br />
                            {{ translate("For upsell text, (to be shown when a service is not active for a client) this is set in each client\'s editor.") }}
                        </template>
                    </form-editor>
                </div>
            </details>
        </div>
    </div>
</template>

<script>
import { handles_saving } from "@/nibnut/mixins"
import { handles_dynamic_tokens } from "@/custom/mixins"

import { FormInput, FormEditor } from "@/nibnut/components"

export default {
    name: "ReportSections",
    mixins: [handles_saving, handles_dynamic_tokens],
    components: {
        FormInput,
        FormEditor
    },
    methods: {
        section_tokens (section_id) {
            if(section_id === "threats") return this.tokens_for_service(this.constants("services", "DNS_FILTER").id)
            if(section_id === "computers") return this.tokens_for_service(this.constants("services", "BITDEFENDER").id)
            if(section_id === "support") return this.tokens_for_service(this.constants("services", "ZEN_DESK").id)
            return []
        },
        save () {
            return this.save_field(this.record.report_sections, "report_sections")
        }
    },
    computed: {
        fields () {
            return ["fieldset::list"]
        }
    },
    props: {
        record: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            entity: "setting",
            record_id: 1
        }
    }
}
</script>

<style lang="scss">
#report-sections {
    .nibnut-editor {
        max-width: calc(100% - 2px);
    }
}
</style>
