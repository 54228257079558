<template>
    <div
        id="beta-tests"
    >
        <h6>{{ translate("Beta Tests") }}</h6>
        <data-table
            id="beta-tests-list"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="!shell_record"
            :clickable="false"
            :searchable="false"
            @sort="sort_by"
            @page="goto_page"
            @add="add_row"
        >
            <template
                v-slot:tbody="{ row, field }"
            >
                <form-input
                    v-if="field === 'name'"
                    :id="`beta-test-${row.id}-name`"
                    name="name"
                    :value="row.name"
                    :required="true"
                    @input="save_row(row, $event, field)"
                >
                    <template v-slot:hint>
                        <span
                            v-if="row.id"
                            class="copiable"
                            v-clipboard:copy="row.id"
                        >
                            {{ row.id }} <open-icon glyph="clipboard" />
                        </span>
                    </template>
                </form-input>
                <form-toggle-input
                    v-else-if="field === 'is_active'"
                    :id="`beta-test-${row.id}-is_active`"
                    name="is_active"
                    type="checkbox"
                    :value="row.is_active"
                    :required="false"
                    @input="save_row(row, $event, field)"
                />
                <div
                    v-else-if="field === '_buttons'"
                    class="columns"
                >
                    <div class="column">
                        <default-button
                            v-if="!!row.id"
                            flavor="link"
                            color="error"
                            @click.prevent.stop="confirm_remove(row)"
                        >
                            <open-icon glyph="trash" />
                        </default-button>
                        <default-button
                            v-else
                            color="error"
                            @click.prevent.stop="shell_record = null"
                        >
                            <open-icon glyph="times" />
                        </default-button>
                    </div>
                    <div v-if="!row.id" class="column">
                        <default-button
                            color="success"
                            @click.prevent.stop="create"
                        >
                            <open-icon glyph="check" />
                        </default-button>
                    </div>
                </div>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>
        <confirmation
            v-if="confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script>
import { is_remote_data_table_source, handles_saving, confirms } from "@/nibnut/mixins"

import { FormInput, FormToggleInput, DefaultButton, OpenIcon } from "@/nibnut/components"

export default {
    name: "BetaTests",
    mixins: [is_remote_data_table_source, handles_saving, confirms],
    components: {
        FormInput,
        FormToggleInput,
        DefaultButton,
        OpenIcon
    },
    methods: {
        add_row () {
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: this.entity }
            ).then(record => {
                this.shell_record = record
                setTimeout(() => {
                    const node = document.getElementById(`beta-test-${this.shell_record.id}-name`)
                    if(node) {
                        node.focus()
                        node.select()
                    }
                }, 350)
            }).catch(error => {
                this.$error(error.message)
            })
        },
        save_row (row, value, field) {
            if(row[field] !== value) {
                row[field] = value
                if(row.id) this.save_field_for_record_id(this.entity, row.id, row[field], field)
            }
        },
        create () {
            if(this.shell_record) {
                this.save_data_for_record_id(this.entity, 0, this.shell_record).then(record => {
                    if(record) {
                        this.shell_record = null
                        this.refresh()
                    }
                })
            }
        },
        confirm_remove (row) {
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete Beta Test"),
                    message: this.$root.translate("Do you really want to delete the test {name}? There is no undo...", { name: row.name }),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete"),
                    record_id: row.id
                },
                "delete-record"
            )
        },
        confirmed () {
            this.$store.dispatch(
                "RECORD_DELETE",
                {
                    entity: this.entity,
                    id: this.confirmation_props.record_id
                }
            ).then(() => {
                this.refresh()
                this.done_confirming()
            }).catch(this.receive_error)
        }
    },
    computed: {
        can_reload () {
            return this.profile_id
        },
        state_identifier () {
            return "beta-tests"
        },
        fields () {
            return ["fieldset::list"]
        },
        filtered_rows () {
            let rows = this.rows.slice()

            rows = this.sort_rows(rows)

            if(this.shell_record) rows.push(this.shell_record)

            return rows
        }
    },
    data () {
        return {
            entity: "beta_test",
            entityName: "test:::tests",
            columns: {
                name: { label: "Name", sort: "asc", type: "alpha" },
                is_active: { label: "Active", sort: false, head_class: "col-auto", cell_class: "col-auto" },
                _buttons: { label: " ", sort: false, head_class: "col-auto", cell_class: "col-auto" }
            },
            default_state: {
                per_page: 5,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },
            shell_record: null
        }
    }
}
</script>

<style lang="scss">
#beta-tests {
    table.table {
        tr {
            & > th, & > td {
                white-space: nowrap;
                vertical-align: top;

                .columns {
                    flex-wrap: nowrap;
                }

                &.col-auto {
                    width: 1px;

                    .form-checkbox {
                        padding: 0;

                        .form-icon {
                            left: 50%;
                            transform: translate(-50%, 0);
                        }
                    }
                }
            }
        }
    }
}
</style>
