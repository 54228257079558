<template>
    <div id="report-notes" class="card">
        <div class="card-header">
            <div class="card-title h6">
                <div class="d-flex align-items-center">
                    <div class="flex-variable">
                        {{ $root.translate("Report Notes") }}
                    </div>
                    <form-toggle-input
                        id="archived"
                        name="archived"
                        type="checkbox"
                        size="sm"
                        :value="state.archived"
                        :required="false"
                        class="flex-static"
                        @input="toggle_archived"
                    >
                        {{ $root.translate('Include trashed items') }}
                    </form-toggle-input>
                </div>
            </div>
        </div>
        <div class="card-body">
            <data-table
                id="report-notes-list"
                :columns="visible_columns"
                :rows="filtered_rows"
                :search="state.search"
                :total="state.total"
                :found="state.found"
                :current-page="state.page"
                :total-pages="nb_pages"
                :can-add="true"
                :export-url="export_url"
                @sort="sort_by"
                @search="search"
                @page="goto_page"
                @add="record_create"
                @click="record_edit"
            >
                <template
                    v-slot:summary="{ row }"
                >
                    <h6 :class="{ 'text-gray': !!row.deleted_at }">
                        {{ row.publish_on | nibnut.date("MMMM yyyy") }}
                        <span class="text-gray text-small">({{ translate("Sent on {date}", { date: send_date_for_publication(row.publish_on) }) }})</span>
                    </h6>
                    <div :class="{ 'text-gray': !!row.deleted_at }">{{ raw_content_for_row(row) | nibnut.truncate(70) }}</div>
                    <default-button
                        v-if="!row.deleted_at"
                        flavor="link"
                        color="error"
                        size="sm"
                        :block="true"
                        @click.prevent.stop="record_delete_or_restore(row)"
                    >
                        <open-icon glyph="trash" /> {{ $root.translate("Trash") }}
                    </default-button>
                    <default-button
                        v-else
                        flavor="link"
                        color="light"
                        size="sm"
                        :block="true"
                        @click.prevent.stop="record_delete_or_restore(row)"
                    >
                        <open-icon glyph="trash-restore" /> {{ $root.translate("Restore") }}
                    </default-button>
                </template>
                <template
                    v-slot:tbody="{ row, field }"
                >
                    <span v-if="field === 'publish_on'" :class="{ 'text-gray': !!row.deleted_at }">
                        {{ row.publish_on | nibnut.date("MMMM yyyy") }}
                        <span class="text-gray text-small">({{ translate("Sent on {date}", { date: send_date_for_publication(row.publish_on) }) }})</span>
                    </span>
                    <span v-else-if="field === 'content'" :class="{ 'text-gray': !!row.deleted_at }">{{ raw_content_for_row(row) | nibnut.truncate(70) }}</span>
                    <div v-else-if="field === '_buttons'" class="text-right">
                        <default-button
                            v-if="!row.deleted_at"
                            flavor="link"
                            color="error"
                            size="sm"
                            @click.prevent.stop="record_delete_or_restore(row)"
                        >
                            <open-icon glyph="trash" />
                        </default-button>
                        <default-button
                            v-else
                            flavor="link"
                            color="light"
                            size="sm"
                            @click.prevent.stop="record_delete_or_restore(row)"
                        >
                            <open-icon glyph="trash-restore" />
                        </default-button>
                    </div>
                    <span v-else :class="{ 'text-gray': !!row.deleted_at }">{{ row[field] }}</span>
                </template>
            </data-table>

            <modal-dialog
                id="report-note-editor"
                :show.sync="editing"
            >
                <template v-slot:title>
                    <span class="h5">{{ $root.translate("Report Note") }}</span>
                </template>
                <base-form
                    v-if="!!edited_record"
                    :has-required-fields="true"
                    @submit.prevent="maybe_save_record"
                >
                    <label class="form-label">{{ $root.translate("Show on Report of") }}</label>
                    <div class="columns">
                        <div class="column col-6 col-sm-12">
                            <form-dropdown
                                id="publish_on"
                                name="month"
                                :value="month"
                                :options="months"
                                :required="true"
                                :saving="saving('publish_on')"
                                :error="has_error('publish_on')"
                                @input="save_date"
                            />
                        </div>
                        <div class="column col-6 col-sm-12">
                            <form-dropdown
                                id="year"
                                name="year"
                                :value="year"
                                :options="years"
                                :required="true"
                                @input="save_date"
                            />
                        </div>
                    </div>
                    <div
                        v-if="month"
                        class="form-input-hint"
                    >
                        {{ $root.translate("The {month} report ({month} {from} - {to}), will be sent on {sent_on}", { month: selected_month, from: selected_from, to: selected_to, sent_on: selected_send_date }) }}
                    </div>
                    <form-editor
                        id="content"
                        :data-version="edited_record.id"
                        name="content"
                        v-model="edited_record.content"
                        size="lg"
                        :required="true"
                        :saving="saving('content')"
                        :error="has_error('content')"
                        @input="save"
                    >
                        <template v-slot:label>{{ $root.translate("Content") }}</template>
                    </form-editor>
                </base-form>
                <template v-slot:footer>
                    <div v-if="!!edited_record && !!!edited_record.id" class="text-center">
                        <default-button
                            class="mr-2"
                            @click.prevent="close"
                        >
                            {{ $root.translate("Cancel") }}
                        </default-button>
                        <default-button
                            color="primary"
                            class="ml-2"
                            @click.prevent="save_record"
                        >
                            {{ $root.translate("Save") }}
                        </default-button>
                    </div>
                </template>
            </modal-dialog>
        </div>
    </div>
</template>

<script>
import {
    format,
    parseISO,
    setMonth,
    setYear,
    startOfMonth,
    endOfMonth,
    add
} from "date-fns"

import { is_remote_data_table_source, handles_saving, handles_dates } from "@/nibnut/mixins"

import {
    DefaultButton,
    OpenIcon,
    ModalDialog,
    BaseForm,
    FormToggleInput,
    FormDropdown,
    FormEditor
} from "@/nibnut/components"

export default {
    name: "ReportNotes",
    mixins: [is_remote_data_table_source, handles_saving, handles_dates],
    components: {
        DefaultButton,
        OpenIcon,
        ModalDialog,
        BaseForm,
        FormToggleInput,
        FormDropdown,
        FormEditor
    },
    methods: {
        raw_content_for_row (row) {
            return row.content.replace(/<[^>]+>/ig, " ")
        },
        record_edit (record) {
            this.edited_record = record
            this.editing = true
        },
        record_create () {
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: this.entity }
            ).then(record => {
                this.record_edit(record)
            }).catch(error => {
                this.$error(error.message)
            })
        },
        save (value, field) {
            if(this.edited_record) {
                if(this.edited_record[field] !== value) this.edited_record[field] = value
                if(this.edited_record.id) return this.save_field_for_record_id(this.entity, this.edited_record.id, this.edited_record[field], field)
            }
            return Promise.resolve()
        },
        save_date (value, field) {
            // "June 2023" is the report sent on JULY 1st, that spans June 1st to June 30th
            // So we always set it to the first of the report data RANGE, in this example, publish_on="June 1st"
            const publish_on = startOfMonth(parseISO(this.edited_record.publish_on))
            if(field === "month") {
                this.edited_record.publish_on = format(setMonth(publish_on, value), "yyyy-MM-dd 00:00:00")
            } else if(field === "year") {
                this.edited_record.publish_on = format(setYear(publish_on, value), "yyyy-MM-dd 00:00:00")
            }
            if(this.edited_record.id) return this.save_data_for_record_id(this.entity, this.edited_record.id, { publish_on: this.edited_record.publish_on })
            return Promise.resolve()
        },
        save_record () {
            this.save_data_for_record_id(this.entity, this.edited_record.id, this.edited_record).then(() => {
                if(!this.has_error()) {
                    this.refresh()
                    this.close()
                }
            })
        },
        maybe_save_record () {
            if(!this.edited_record || !!this.edited_record.id) this.close()
            else this.save_record()
        },
        record_delete_or_restore (record) {
            this.$store.dispatch(
                !record.deleted_at ? "RECORD_DELETE" : "RECORD_RESTORE",
                {
                    entity: this.entity,
                    id: record.id
                }
            ).then(record => {
                if(!record.deleted_at) this.current_records_ids.push(record.id)
                else {
                    const index = this.current_records_ids.indexOf(record.id)
                    if(index >= 0) this.current_records_ids.splice(index, 1)
                }
                this.refresh()
            }).catch(this.receive_error)
        },
        close () {
            this.edited_record = {}
            this.editing = false
        },
        send_date_for_publication (date) {
            return format(startOfMonth(add(parseISO(date), { months: 1 })), "MMMM d")
        }

    },
    computed: {
        state_identifier () {
            return "report-notes-list"
        },
        fields () {
            return ["fieldset::default"]
        },
        month () {
            return this.month_for_date(this.edited_record.publish_on)
        },
        year () {
            return this.year_for_date(this.edited_record.publish_on)
        },
        years () {
            const current_year = this.year_for_date(new Date())
            const years = []
            const selected_year = this.year
            if(selected_year < current_year) years.push({ id: selected_year, name: format(new Date(selected_year, 0, 1), "yyyy") })
            for(let year = current_year; year < (current_year + 5); year++) years.push({ id: year, name: format(new Date(year, 0, 1), "yyyy") })
            return years
        },
        selected_month () {
            return format(startOfMonth(parseISO(this.edited_record.publish_on)), "MMMM")
        },
        selected_from () {
            return format(startOfMonth(parseISO(this.edited_record.publish_on)), "d")
        },
        selected_to () {
            return format(endOfMonth(parseISO(this.edited_record.publish_on)), "d")
        },
        selected_send_date () {
            return this.send_date_for_publication(this.edited_record.publish_on)
        }
    },
    data () {
        return {
            entity: "report_note",
            columns: {
                publish_on: { label: "Report", sort: "desc", type: "amount" },
                content: { label: "Excerpt", sort: false },
                _buttons: { label: " ", sort: false }
            },
            default_state: {
                per_page: 5,
                page: 1,
                sort_by: "publish_on",
                sort_dir: "desc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },
            editing: false,
            edited_record: {}
        }
    }
}
</script>

<style lang="scss">
#report-notes {
    table.table {
        td {
            vertical-align: top;
        }
    }
    #report-note-editor {
        & > .modal-container {
            height: 90vh;
            min-height: 90vh;
            max-width: 75vw;

            .modal-body {
                flex: 1 1 auto;
                overflow: hidden;
                display: flex;
                flex-direction: column;

                & > form {
                    height: 100%;
                    display: flex;
                    flex-direction: column;

                    & > * {
                        flex: 0 0 auto;
                    }
                    & > .form-group.full-height {
                        flex: 1 1 auto;
                    }
                    .nibnut-editor.nibnut-editor-lg > div:first-of-type {
                        &, & > .nibnut-editor-content {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
}
</style>
